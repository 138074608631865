import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { get, last, compact } from "lodash";
import { Checkbox } from "antd";
import cx from "classnames";

import { ContentBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { makeUrl, removeEmptyKeys, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import NoScript from "../../components/NoScript";
import { SAINT_BOOK, SAINT_BOOK_MAX_UPDATE } from "../../queries/queries.graphql";
import EmptyData from "../../components/EmptyData";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query GospelQuery($slug: String!) {
		hasura {
			...SaintBook
		}
	}
`;

export default function SaintBook({ data, location }) {
	const { theme } = useThemeContext();

	const [saintBook, setSaintBook] = useState(get(data, "hasura.saint_books[0]", {}));
	const [rusText, setRusText] = useState(["rus_text_r", "church_rus_text_c"]);

	const maxUpdated = new Date(get(data, "hasura.saint_books_aggregate.aggregate.max.updated_at", new Date()));

	const path = last(compact(get(location, "pathname", "").split("/")));

	const saintBookOptions = {
		variables: {
			location: path,
		},
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(SAINT_BOOK_MAX_UPDATE, saintBookOptions);

	const [loadSaintBook, { called: saintBookCalled, loading: saintBookLoading, data: saintBookData, error: saintBookError }] = useLazyQuery(SAINT_BOOK, saintBookOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "saint_books_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadSaintBook();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load SaintBookMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (saintBookData && !saintBookError) {
			setSaintBook(get(saintBookData, "saint_books[0]", saintBook));
			removePreloader(saintBookCalled);
		} else if (saintBookError) {
			console.error("Invalid load SaintBookData", { saintBookError });
			removePreloader();
		}
	}, [saintBookLoading]);

	const name = get(saintBook, "name", "");
	const content_blocks = get(saintBook, "content_blocks", []);
	const url = makeUrl.saint_book({ slug: path });

	function makeRedText(text) {
		const reg = /\[(Зач.*?)\]/;
		const match = text.match(reg);
		const redText = get(match, "[1]", "");
		const [a, b] = text.split(redText);

		return redText ?
			<div className={cx("col-6", { "col-12": rusText.length === 1 })} style={{ marginBottom: 20,
				textAlign: "justify" }}>
				<span>{a}</span>
				<span style={{ color: "red" }}>{redText}</span>
				<span>{b}</span>
			</div> :
			<div className={cx("col-6", { "col-12": rusText.length === 1 })} style={{ marginBottom: 20,
				textAlign: "justify" }}>
				{text}
			</div>;
	}

	function handleLangChange(value) {
		setRusText(value);
	}

	useEffect(() => {
		removePreloader();
	}, []);

	return content_blocks ? (
		<Pages entity={saintBook} url={url} >
			<ContentBlock key={"Breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={name} />
				</div>
			</ContentBlock>
			<ContentBlock
				key={"media-content"}
				mount={!!content_blocks.length}
			>
				<div className={"container"}>
					<div className={"h1"}>
						<h1 itemProp="headline" style={removeEmptyKeys({
							fontFamily: theme.fontFamily,
							fontSize: theme.fontSize.h1,
							lineHeight: theme.lineHeight.h1,
							color: theme.color.text,
						})}>{name}</h1>
					</div>
					<Checkbox.Group
						options={[
							{ label: "Синодальный",
								value: "rus_text_r" },
							{ label: "Церковнославянский (рус)",
								value: "church_rus_text_c" },
						]}
						defaultValue={["rus_text_r", "church_rus_text_c"]}
						onChange={handleLangChange}
						className={"d-noscript-none"}
					/>
					<NoScript>
						<Checkbox.Group
							options={[
								{ label: "Синодальный",
									value: "rus_text_r" },
								{ label: "Церковнославянский (рус)",
									value: "church_rus_text_c" },
							]}
							defaultValue={["rus_text_r", "church_rus_text_c"]}
							disabled
						/>
					</NoScript>
					<div className={"row"} style={{
						margin: "20px 0",
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					}}>
						{ rusText.includes("rus_text_r") && makeRedText(get(content_blocks, "[0].body", "")) }
						{ rusText.includes("church_rus_text_c") && makeRedText(get(content_blocks, "[1].body", "")) }
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleFull={name} />
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

SaintBook.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
	location: PropTypes.object,
};

SaintBook.defaultProps = {
	data: {},
	pageContext: {},
	location: {},
};
